import Content from '~/components/elements/Content'
import type { Maybe, MediaItem, Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import { useEffect, useState } from 'react'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import { FormDuufField, useFormDuuf } from '@ubo/form-duuf'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import Fingerprint from '~/components/elements/Fingerprint'

export default function FormEmployeeExperience({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [, setFormStatus] = useState('')

  return (
    <section
      data-component="FormEmployeeExperience"
      id={fields.sectionId ?? undefined}
      className="pt-12 lg:pt-24 pb-12 overflow-hidden lg:py-24 bg-eo-ribombee"
    >
      <Fingerprint className="absolute -right-40 bottom-20 opacity-20" />
      <div className="container">
        <div className="flex justify-center">
          <div className="lg:w-[82%]">
            <div className="grid grid-cols-1 lg:grid-cols-2 items-center">
              <div className="col-span-1">
                <Content className="children-headings:text-3xl lg:children-headings:text-7xl children-headings:text-eo-buizel">
                  {fields.title}
                </Content>
              </div>
              <div className="col-span-1">
                <Content className="children-headings:text-xl lg:children-headings:text-2xl children-headings:text-eo-buizel">
                  {fields.description}
                </Content>
              </div>
            </div>

            <Form
              scrollToConfirm={false}
              className="form-employee-experience mt-8"
              generate={false}
              data={fields.form}
              renderLoading={() => <Loading />}
              renderStatus={({ status, content }) => {
                setFormStatus(status)

                return <Content>{content}</Content>
              }}
            >
              <FormInner fields={fields} />
            </Form>
          </div>
        </div>
      </div>
    </section>
  )
}

function FormInner({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const form = useFormDuuf()
  const [currentStep, setCurrentStep] = useState(0)
  const [fieldArray, setFieldArray] = useState<any[]>([])
  const [totalPoints, setTotalPoints] = useState(0)

  useEffect(() => {
    let putIndex = -1
    const array: any[] = []

    form.fields.forEach((field) => {
      // @ts-ignore
      if (field.type === 'SECTION') {
        putIndex = putIndex + 1
      } else if (putIndex >= 0) {
        if (!array[putIndex]) {
          array[putIndex] = [field]
        } else {
          array[putIndex] = [...array[putIndex], field]
        }
      }
    })

    setFieldArray(array)
  }, [form.fields])

  function handlePrev() {
    setCurrentStep((prev) => prev - 1)
  }

  useEffect(() => {
    form.formik.values['input_9'] = totalPoints
  }, [totalPoints, form.formik.values])

  function handleNext() {
    let fakePoints = 0

    fieldArray.forEach((fields) => {
      fields.forEach((field: any) => {
        if (field.type === 'RADIO') {
          const points = parseInt(form.formik.values[`input_${field.id}`])

          if (!Number.isNaN(points)) {
            fakePoints = fakePoints + points
          }
        }
      })
    })

    setTotalPoints(fakePoints)
    setCurrentStep((prev) => prev + 1)
  }

  return (
    <>
      <div className="flex items-center my-7">
        <span className="text-eo-whiscash font-bold">Voortgang</span>
        <div className="ml-4 relative w-full bg-white rounded-full h-5">
          <div
            style={{
              width: `${(currentStep / fieldArray.length) * 100}%`
            }}
            className="h-full absolute smooth rounded-full bg-eo-whiscash"
          >
            <div className="absolute text-sm text-eo-whiscash font-bold top-0 bottom-0 my-auto -right-12">{`${(
              (currentStep / fieldArray.length) *
              100
            ).toFixed(0)}%`}</div>
          </div>
        </div>
      </div>
      <div className="lg:w-3/4 lg:ml-36">
        {currentStep === 7 && (
          <div className="mb-8 mt-4">
            <ChatBubble
              image={fields.image}
              content="
                Je bent er bijna, spannend! Laat hier je gegevens achter en ontdek hoe hoog de eindgebruiker op jouw IT-agenda staat. De
                uitslag ontvang je van ons vervolgens ook in de mail."
            />
          </div>
        )}
        {currentStep === 0 && (
          <div className="mb-7">
            <ChatBubble image={fields.image} content={fields.chatDescription} />
          </div>
        )}

        <AnimatePresence exitBeforeEnter>
          {fieldArray[currentStep] &&
            fieldArray[currentStep].map((field: any, index: number) => {
              return (
                <motion.div
                  key={field.id}
                  initial={{ opacity: 0, x: '-100%' }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: '100%' }}
                  transition={{ bounce: 0 }}
                >
                  <div className={clsx(currentStep < 7 && 'mt-20', 'relative')}>
                    {currentStep < 7 && (
                      <div className="absolute bottom-[92.5%] text-xl font-semibold px-6 pt-2 pb-7 left-0 bg-eo-whiscash rounded-t-3xl text-white">{`Stelling ${
                        currentStep + 1
                      }.`}</div>
                    )}

                    <FormDuufField id={field.id} />
                  </div>
                  {field.type === 'RADIO' && (
                    <div className="flex justify-between mb-12">
                      <span className="font-semibold">Helemaal oneens</span>
                      <span className="font-semibold">Helemaal eens</span>
                    </div>
                  )}
                </motion.div>
              )
            })}
        </AnimatePresence>
        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => handlePrev()}
            className={clsx(currentStep === 0 ? 'opacity-0' : 'opacity-100', 'btn--blue--transparent--reverse')}
          >
            Vorige vraag
          </button>
          <AnimatePresence exitBeforeEnter>
            {currentStep === fieldArray.length - 1 ? (
              <motion.button
                initial={{ pointerEvents: 'none' }}
                animate={{ pointerEvents: 'auto' }}
                exit={{ pointerEvents: 'none' }}
                type="submit"
                className="btn--blue--transparent"
                // TODO: fixen
                disabled={
                  !form.formik.values[`input_24`] ||
                  !form.formik.values[`input_22`] ||
                  !form.formik.values[`input_20`] ||
                  !form.formik.values[`input_21`]
                }
              >
                Verzenden
              </motion.button>
            ) : (
              <button
                disabled={fieldArray[currentStep] && form.formik.values[`input_${fieldArray![currentStep]![0]?.id}`] === ''}
                type="button"
                onClick={() => handleNext()}
                className="btn--blue--transparent disabled:opacity-30 smooth"
              >
                Volgende vraag
              </button>
            )}
          </AnimatePresence>
        </div>
      </div>
    </>
  )
}

function ChatBubble({ image, content }: { image: Maybe<MediaItem> | undefined; content: Maybe<string | undefined> }) {
  return (
    <div className="relative flex items-end">
      <div className="relative bg-white shadow-lg rounded-t-3xl rounded-br-3xl px-5 lg:px-12 py-4 lg:py-7">
        <svg
          width="76"
          height="20"
          viewBox="0 0 76 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute bottom-0 -left-4"
        >
          <path fillRule="evenodd" clipRule="evenodd" d="M0 20H75.9558L18.6395 0L0 20Z" fill="white" />
        </svg>
        <Content>{content}</Content>
      </div>
      <LossePlaatjie maxwidth={80} src={image} className="min-w-[5rem] max-w-[5rem] -mb-8 absolute -left-24 bottom-0" />
    </div>
  )
}
